<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model layout="inline" @keyup.enter.native="query">
        <a-row>
          <a-col :span="22">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="酒水品名" />
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                placeholder="类型"
                v-model="form.typeInv"
                @change="onTypeChange"
                style="width: 180px"
              >
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select
                v-model="form.typeSub"
                placeholder="子类型"
                style="width: 180px"
              >
                <a-select-option
                  v-for="item in subTypeList"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-col>

          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button
                v-if="$getPermission($route.path + '/add')"
                @click="$router.push($route.path + '/add')"
                type="primary"
                >新建</a-button
              >
              <a-button
                style="margin-left: 10px;"
                v-if="$getPermission($route.path + '/addStorage')"
                @click="$router.push($route.path + '/addStorage')"
                type="primary"
                >新增入库单</a-button
              >
            </div>
          </a-col>
        </a-row>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="酒水品名" data-index="name"> </a-table-column>

        <a-table-column title="库存" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">
              {{ text.quantity }}
            </span>
          </template>
        </a-table-column>

        <a-table-column title="类型">
          <template slot-scope="text">
            <div class="left">
              <DataDictFinder dictType="invType" :dictValue="text.typeInv" />
              <span>-</span>
              {{ renderSubType(text.typeInv, text.typeSub) }}
            </div>
          </template>
        </a-table-column>

        <a-table-column title="单位" data-index="unit" align="center">
        </a-table-column>
        <a-table-column title="单价" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.unitPrice }}</span>
          </template>
        </a-table-column>

        <a-table-column title="操作" align="right" width="120px">
          <template slot-scope="text">
            <a-space>
              <!-- <Adjust
                :text="text"
                @refresh="refresh"
                v-if="$getPermission($route.path + '/adjust')"
              /> -->
              <a
                href="#"
                @click="editText(text)"
                v-if="$getPermission($route.path + '/edit')"
                >编辑</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList } from "@/api/oa/material";
import { mapGetters } from "vuex";
import Adjust from "./components/adjust.vue";
export default {
  mixins: [watermark],

  components: {
    Adjust,
  },

  data() {
    return {
      form: {},
      subTypeList: [], // 子类型列表

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("invType");
    },
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    refresh() {
      this.getList();
    },

    editText(text) {
      window.localStorage.setItem("edit-material", JSON.stringify(text));
      this.$router.push(this.$route.path + `/edit`);
    },

    renderSubType(type, subType) {
      const obj = this.typeList.find((item) => item.value === type);
      if (obj && Array.isArray(obj.children)) {
        const subTypeObj = obj.children.find((item) => item.value === subType);
        if (subTypeObj) {
          return subTypeObj.name;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    onTypeChange(value) {
      if (value) {
        const obj = this.typeList.find((item) => item.value === value);
        if (obj && Array.isArray(obj.children)) {
          this.subTypeList = obj.children;
        }
      } else {
        this.subTypeList = [];
      }
    },
  },
};
</script>