<template>
  <span>
    <a href="#" @click.prevent="visible = true">调整库存</a>

    <a-modal
      :title="title"
      :visible="visible"
      @cancel="cancel"
      @ok="ok"
      width="320px"
    >
      <a-input-number
        v-model="quantity"
        placeholder="增加库存填正数，减少库存填负数"
        style="width: 100%"
      />
    </a-modal>
  </span>
</template>

<script>
import { adjust } from "@/api/oa/material";
export default {
  props: {
    text: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      title: `调整${this.text.name}库存`,
      visible: false,
      quantity: null,
    };
  },

  methods: {
    cancel() {
      this.visible = false;
    },
    ok() {
      if (typeof this.quantity === "number") {
        adjust({
          id: this.text.id,
          quantity: this.quantity,
        }).then(() => {
          this.quantity = null;
          this.visible = false;
          this.$emit("refresh");
        });
      }
    },
  },
};
</script>